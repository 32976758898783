import type { PropsWithChildren } from "react";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { useElementRect } from "clutch/src/util/use-element-rect.mjs";

import Ripple from "@/shared/Ripple.jsx";
import { useInertTransition } from "@/shared/Transition.jsx";
import BlitzPortal from "@/util/BlitzPortal.jsx";
import { classNames } from "@/util/class-names.mjs";
import useForwardedRef from "@/util/use-forwarded-ref.mjs";
import useKeypress from "@/util/use-key-press.mjs";

import MenuContextProvider, {
  useMenuContextProvider,
} from "../Menu/MenuContextProvider.js";
import { useScreenSize } from "../util/use-screen-size.mjs";

type MenuHandle = {
  top?: number;
  left?: number;
  open: (e: MouseEvent, preventDefault: boolean) => void;
  close: () => void;
};
type Props = {
  children: React.ReactNode | React.ReactNode[];
  trigger?: React.ReactNode;
  menuRef?: React.MutableRefObject<MenuHandle>;
  triggerSize?: string;
  defaultOpen?: boolean;
  triggerCapture?: boolean;
} & React.ComponentPropsWithoutRef<"menu">;

const MenuInContext: React.FC<Props> = (props) => (
  <MenuContextProvider>
    <MenuImpl {...props} />
  </MenuContextProvider>
);

const MenuImpl: React.FC<Props> = ({
  trigger,
  triggerSize,
  defaultOpen = false,
  children,
  menuRef: menuHandleRef,
  triggerCapture: capture = false,
  ...restProps
}) => {
  const { open, setOpen } = useMenuContextProvider();

  const targetRef = useRef<HTMLDivElement>(null);
  const parentRect = useElementRect(targetRef.current);
  const dropdownRef = useRef<HTMLMenuElement>(null);
  const pos = useRef<{ top: number; left: number } | null>(null);
  const clickHandler = useCallback<React.MouseEventHandler>(
    (e) => {
      setOpen((open) => !open);
      if (capture) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [capture, setOpen],
  );
  const clickEvent = useMemo(
    () => (capture ? "onClickCapture" : "onClick"),
    [capture],
  );

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen, setOpen]);

  useEffect(() => {
    if (!open) {
      pos.current = null;
    }
  });

  // register menu handle fns
  useEffect(() => {
    if (!menuHandleRef) return;
    menuHandleRef.current = {
      open: (e: MouseEvent, preventDefault = true) => {
        if (e?.type) {
          pos.current = { top: e.clientY, left: e.clientX };
          if (preventDefault) e.preventDefault();
        }
        setOpen(true);
      },
      close: () => setOpen(false),
    };
  }, [menuHandleRef, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useKeypress("Escape", handleClose);

  useEffect(() => {
    if (!targetRef.current) return;

    const elem = targetRef.current;

    function handleOutsideClick(event: MouseEvent) {
      if (
        !(event.target instanceof Node) ||
        (event.target as HTMLElement).closest("[data-ignore-outside-click]") ||
        targetRef.current?.contains(event.target)
      ) {
        return;
      }

      handleClose();
    }

    elem?.ownerDocument.addEventListener("mousedown", handleOutsideClick);

    return () => {
      elem?.ownerDocument.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleClose]);

  useEffect(() => {
    if (!dropdownRef.current) return;

    dropdownRef.current.focus();
  }, []);

  return (
    <>
      <Trigger
        ref={targetRef}
        $size={triggerSize}
        role="button"
        className="menu-trigger"
        {...{ [clickEvent]: clickHandler }}
      >
        {trigger}
      </Trigger>

      <Content
        ref={dropdownRef}
        fromElement={targetRef}
        pos={pos.current || parentRect}
        {...restProps}
      >
        {children}
      </Content>
    </>
  );
};

const InnerContent = (
  {
    fromElement,
    children,
    pos = null,
    ...restProps
  }: PropsWithChildren<{
    fromElement: Parameters<typeof BlitzPortal>[0]["fromElement"];
    pos: { top: number; left: number } | null;
  }>,
  fwdRef: React.ForwardedRef<HTMLMenuElement>,
) => {
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const { open } = useMenuContextProvider();
  const screen = useScreenSize();
  const ref = useForwardedRef(fwdRef);

  useLayoutEffect(() => {
    if (!ref.current) return;
    setSize({
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight,
    });
  }, [setSize, ref]);

  const bounds = {
    top: `${pos?.top}px`,
    left: `${pos?.left}px`,
    minWidth: `${size?.width}px`,
  };

  const boundsWithScreenConstraints = {
    top: `clamp(0px, ${bounds.top}, ${screen.height - size.height}px)`,
    left: `clamp(0px, ${bounds.left}, ${screen.width}px - ${bounds.minWidth})`,
    minWidth: `${bounds.minWidth}`,
    maxWidth: `${screen.width}px`,
  };

  const onEnter = (node: HTMLElement) => {
    const menuNode = node.querySelector("menu");
    menuNode?.classList.add(cssHeight(menuNode.scrollHeight));
  };

  const a = useInertTransition<HTMLDivElement>({ onEnter });

  if (!open) return null;

  return (
    <BlitzPortal
      fromElement={fromElement}
      className={cssPortal()}
      ref={a}
      data-anim-init
    >
      <MenuContent
        role="menu"
        ref={ref}
        style={{ ...boundsWithScreenConstraints }}
        {...restProps}
      >
        {children}
      </MenuContent>
    </BlitzPortal>
  );
};

const cssPortal = () => css`
  transition-duration: var(--transition-duration);
  menu {
    transition: var(--transition);
    overflow: hidden;
  }
  &.enter-from menu,
  &.leave-to menu {
    opacity: 0;
    height: 0;
  }
  &.leave menu {
    transition:
      var(--transition),
      opacity var(--transition-duration) var(--ease-out-quad) !important;
  }
`;

const Content = React.forwardRef(InnerContent);

/**
 * Menu Group
 */

type GroupProps = {
  children: React.ReactNode | React.ReactNode[];
};

const Group: React.FC<GroupProps> = ({ children }) => {
  return (
    <MenuGroupWrap>
      <ul>{children}</ul>
      <div className="divider" />
    </MenuGroupWrap>
  );
};

/**
 * Menu Item
 */

type ItemVariant = "primary" | "secondary";

type ItemProps = {
  icon?: React.ReactNode;
  text: Translation;
  href?: string;
  disabled?: boolean;
  inactive?: boolean;
  variant?: ItemVariant;
  onClick?: (event: React.MouseEvent) => void;
} & React.ComponentPropsWithoutRef<"li">;

const Item: React.FC<ItemProps> = ({
  icon,
  text,
  href,
  disabled,
  inactive,
  variant = "primary",
  onClick,
  children,
  className,
  ...restProps
}) => {
  const { setOpen } = useMenuContextProvider();
  const { t } = useTranslation();

  // Validation
  React.useMemo(() => {
    if (!Array.isArray(text)) {
      throw Error(
        `[MenuItem] title must be a Translation, not ${typeof text}. (reference: ${text})`,
      );
    }
  }, [text]);

  const handleOnItemClick = useCallback(
    (event: React.MouseEvent) => {
      onClick?.(event);
      setOpen(false);
    },
    [onClick, setOpen],
  );

  const RenderItem = React.useMemo(
    () =>
      href
        ? ({ role, ...props }: JSX.IntrinsicElements["li"]) => (
            // NOTE: as="a" is not respected as the intrinsic element
            <li role={role}>
              <ItemWrap
                as="a"
                data-ignore-outside-click
                {...{ href, ...props }}
              />
            </li>
          )
        : (props: JSX.IntrinsicElements["li"]) => (
            <ItemWrap data-ignore-outside-click {...props} />
          ),
    [href],
  );

  return (
    <RenderItem
      role="menuitem"
      tabIndex={0}
      onClick={handleOnItemClick}
      {...restProps}
      {...classNames(
        className,
        disabled && "disabled",
        inactive && "inactive",
        variant,
      )}
    >
      {icon && typeof icon === "string" ? (
        <ItemImage src={icon as string} />
      ) : (
        <ItemIcon>{icon}</ItemIcon>
      )}
      <ItemLabel>{t(...text)}</ItemLabel>
      {children}
      <Ripple />
    </RenderItem>
  );
};

/**
 * Footer Item
 */

type FooterProps = {
  children: React.ReactNode | React.ReactNode[];
};

const FooterItem: React.FC<FooterProps> = ({ children }) => {
  return (
    <ItemWrap tabIndex={-1} className="inactive">
      {children}
    </ItemWrap>
  );
};

/**
 * Styled components
 */

const ItemWrap = styled("li")`
  position: relative;
  cursor: pointer;
  list-style: none;
  margin: 0;
  border-radius: var(--br);

  padding: var(--sp-2_5) var(--sp-3);
  display: flex;
  flex-direction: row;
  gap: var(--sp-2);
  text-decoration: none;
  flex: 1;
  align-items: center;

  &.inactive,
  &.disabled {
    cursor: default;
    pointer-events: none;

    &:hover {
      background-color: undefined;
    }
  }

  transition: background-color var(--transition);
  &:hover,
  &:focus {
    background-color: var(--shade6);
  }

  &.disabled {
    opacity: 0.25;
  }

  &.secondary {
    background-color: hsla(var(--turq-hsl) / 0.15);

    * {
      color: var(--turq);
      fill: var(--turq);
    }

    &:hover {
      background-color: hsla(var(--turq-hsl) / 0.25);
    }
  }

  &:visited {
    color: var(--shade0);
  }
`;

const ItemIcon = styled("span")`
  svg {
    width: var(--sp-6);
    height: var(--sp-6);
    fill: var(--shade2);
  }
`;

const ItemImage = styled("img")`
  width: var(--sp-6);
`;

const ItemLabel = styled("span")`
  flex: 1 0;
  font-size: var(--sp-3_5);
`;

const MenuGroupWrap = styled("li")`
  .divider {
    height: 1px;
    width: 100%;
    background-color: var(--shade7);
    margin: var(--sp-2) 0;
  }

  &:last-of-type {
    .divider {
      display: none;
    }
  }
`;

const Trigger = styled("div", forwardRef)<{ $size?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--br);
  cursor: pointer;
  transition: background-color var(--transition);
  ${({ $size }) => {
    return $size
      ? `
      width: ${$size};
      height: ${$size};
    `
      : undefined;
  }}

  * {
    transition: opacity var(--transition);
    opacity: 60%;
  }

  &:hover {
    background-color: var(--shade6);
    * {
      opacity: 100%;
    }
  }
`;

const cssHeight = (height: number) => css`
  --height: ${height}px;
`;

const MenuContent = styled("menu", forwardRef)`
  --content-padding: var(--sp-1_5);

  position: fixed;
  z-index: 999;

  border-radius: var(--br);
  padding: var(--content-padding) !important;

  background-color: var(--shade10);
  color: var(--shade0);

  transition: var(--transition);
  overflow: hidden;

  height: var(--height, auto);
  &.enter-from,
  &.leave-to {
    opacity: 0;
    height: 0;
  }
  &.leave {
    transition:
      var(--transition),
      opacity var(--transition-duration) var(--ease-out-quad) !important;
  }
`;

export const Menu = Object.assign(MenuInContext, { Item, Group, FooterItem });
export default Menu;
